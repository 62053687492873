<template>
  <div class="home">
    <div class="banner"><img src="../assets/images/banner1.jpg" alt="" /></div>
    <div class="current-position">
      <el-breadcrumb>
        <el-breadcrumb-item :to="{ path: '/' }"
          ><span class="icon-home"></span>首页</el-breadcrumb-item
        >
        <el-breadcrumb-item>联系我们</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="foot-detail">
      <h3>联系我们</h3>
      <p>传真：010-59757686</p>
      <p>电子邮箱：ccpress@ccpress.com.cn</p>

      <p>办公地址：北京朝阳区小营北路17号中交文苑（100101）</p>

      <p>通讯地址：北京朝阳区小营北路17号中交文苑（100101）</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'home'
}
</script>
